import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {FC, useEffect, useState} from 'react'
import CardButton from '../../components/UI/card-button/CardButton'
import {AplicacionDTO} from '../../models/DTOs/aplicaciones/AplicacionDTO'
import {showError} from '../../helpers/AlertHelper'
import {errorMessageBuilder} from '../../helpers/error-message-builder'
import {useGetAplicacionesQuery} from '../../modules/aplicaciones/get-aplicaciones'
import TituloBreadcrumbs from '../../components/UI/TituloBreadCrumbs'
import FormSimpleLoader from '../../components/UI/loaders/FormSimpleLoader'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../setup'
import {useCreateOTATokenMutation} from '../../modules/auth/create-ota-token'
import {IAuthState} from '../auth'

const DashboardWrapper: FC = () => {
  const auth: IAuthState = useSelector<RootState>(({auth}) => auth, shallowEqual) as IAuthState
  const intl = useIntl()

  const [aplicaciones, setAplicaciones] = useState<AplicacionDTO[]>([])
  const [selectedApp, setSelectedApp] = useState<AplicacionDTO>()

  const {isLoading, isError, isFetched, isSuccess, data, error} = useGetAplicacionesQuery()

  useEffect(() => {
    if (!isError && !isSuccess) return

    if (isSuccess && isFetched && data) {
      if (data.IsSuccess) setAplicaciones(data.Value)
    } else if (isError) {
      showError(
        intl.formatMessage({id: 'ERROR.LOADING.APPLICATIONS'}),
        errorMessageBuilder(error, intl.formatMessage),
        intl.formatMessage({id: 'SETTINGS.ACCEPT'})
      )
    }
  }, [isSuccess, isError, isFetched, data])

  const {
    isError: isOTAError,
    isSuccess: isOTASuccess,
    data: OTAData,
    error: OTAError,
    mutate: createOTA,
  } = useCreateOTATokenMutation()

  useEffect(() => {
    if (!isOTAError && !isOTASuccess) return

    if (isOTASuccess && OTAData && selectedApp) {
      window.location.href = `${selectedApp.Link}?ota=${OTAData}`
    } else if (isOTAError) {
      showError(
        intl.formatMessage({id: 'ERROR'}),
        errorMessageBuilder(OTAError, intl.formatMessage),
        intl.formatMessage({id: 'SETTINGS.ACCEPT'})
      )
    }
  }, [isOTASuccess, isOTAError, OTAData, selectedApp])

  const handleCreateOTA = (aplicacion: AplicacionDTO) => {
    setSelectedApp(aplicacion)
    createOTA({
      content: JSON.stringify({
        AccessToken: auth.accessToken,
        RefreshToken: auth.refreshToken,
        Laboratorio: auth.laboratorio,
        Sector: auth.sector,
      }),
    })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: process.env.REACT_APP_NAME})}</PageTitle>
      <TituloBreadcrumbs titulo={intl.formatMessage({id: 'MENU.APPS'})} />

      {isLoading ? (
        <FormSimpleLoader height={500} width={1000} />
      ) : (
        <div className='row'>
          {aplicaciones.map((aplicacion) => {
            if (aplicacion.Id === 1) return

            return (
              <div className='col-4 my-5' key={`dashboard_apps_${aplicacion.Id}`}>
                <CardButton
                  title={aplicacion.Nombre}
                  description={intl.formatMessage({id: aplicacion.Descripcion})}
                  onClick={() => handleCreateOTA(aplicacion)}
                  icon={aplicacion.Icono}
                />
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}

export {DashboardWrapper}
