import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../app/helpers'
import {HeaderUserMenu} from '../../../partials'
import {useLayout} from '../../core'
import {TicketsMenu} from '../../../../app/components/tickets/TicketsMenu'
import ApplicationsMenu from '../../../../app/components/UI/applications-menu/ApplicationsMenu'
import {AplicacionDTO} from '../../../../app/models/DTOs/aplicaciones/AplicacionDTO'
import {errorMessageBuilder} from '../../../../app/helpers/error-message-builder'
import {showError} from '../../../../app/helpers/AlertHelper'
import {useIntl} from 'react-intl'
import {useGetAplicacionesQuery} from '../../../../app/modules/aplicaciones/get-aplicaciones'
import SelectSector from '../../../../app/components/UI/sector/SelectSector'
import {useSelector, shallowEqual} from 'react-redux'
import {UserModel} from '../../../../app/models/UserModel'
import {RootState} from '../../../../setup'
import SelectLabAndSector from '../../../../app/components/UI/labs-and-sectores/SelectLabAndSector'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3'
const toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'
const toolbarButtonIconSizeClass = 'svg-icon-1'
const toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px'

const Topbar: FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const {config} = useLayout()
  const intl = useIntl()

  const {isSuccess, isError, isFetched, data, error} = useGetAplicacionesQuery()

  const [aplicaciones, setAplicaciones] = useState<AplicacionDTO[]>([])

  useEffect(() => {
    if (!isSuccess && !isError) return

    if (isSuccess && isFetched && data) {
      if (data.IsSuccess) setAplicaciones(data.Value)
    } else if (isError) {
      showError(
        intl.formatMessage({id: 'ERROR.LOADING.APPLICATIONS'}),
        errorMessageBuilder(error, intl.formatMessage),
        intl.formatMessage({id: 'SETTINGS.ACCEPT'})
      )
    }
  }, [isSuccess, isFetched, isError, data])

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='d-flex align-items-stretch align-self-center mx-5'>
        {user.IsResponsableFromGrupoLaboratorios ? <SelectLabAndSector /> : <SelectSector />}
      </div>
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_applications_menu_toggle'
      >
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary position-relative',
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen025.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div>
        <ApplicationsMenu aplicaciones={aplicaciones} />
      </div>

      <div
        className={clsx('d-flex align-items-center mx-3', toolbarButtonMarginClass)}
        id='kt_tickets_menu_toggle'
      >
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary position-relative',
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/duotune/communication/com007.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div>
        <TicketsMenu />
      </div>

      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <img
            className='h-25px w-25px rounded'
            src={toAbsoluteUrl('/media/avatars/blank.png')}
            alt='GM'
          />
        </div>
        <HeaderUserMenu />
      </div>

      {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )}
    </div>
  )
}

export {Topbar}
