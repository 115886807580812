import {nanoid} from '@reduxjs/toolkit'
import {FC, useEffect, useState} from 'react'
import {Modal, Button} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {showError, toastError} from '../../../helpers/AlertHelper'
import {errorMessageBuilder} from '../../../helpers/error-message-builder'
import {SectorDTO} from '../../../models/DTOs/sectores/SectorDTO'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../models/UserModel'
import * as auth from '../../../pages/auth/AuthRedux'
import {useGetSectoresQuery} from '../../../modules/sectores/get-sectores'
import {EstadoType} from '../../../models/DTOs/common/types'

type Props = {
  onClose: () => void
}

const ModalSectores: FC<Props> = ({onClose}) => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const sectorActual: SectorDTO = useSelector<RootState>(
    ({auth}) => auth.sector,
    shallowEqual
  ) as SectorDTO

  const [sectores, setSectores] = useState<SectorDTO[]>([])

  const intl = useIntl()
  const dispatch = useDispatch()

  const {
    isSuccess: isUSSuccess,
    isError: isUSError,
    isFetched: isUSFetched,
    data: USData,
    error: USError,
    isLoading: sectoresLoading,
  } = useGetSectoresQuery()

  useEffect(() => {
    if (!isUSError && !isUSSuccess) return

    if (isUSSuccess && isUSFetched && USData) {
      setSectores(USData)
    }
    if (isUSError) {
      showError(
        'Error',
        errorMessageBuilder(USError, intl.formatMessage),
        intl.formatMessage({id: 'SETTINGS.ACCEPT'})
      )
    }
  }, [isUSError, isUSFetched, isUSSuccess, USData])

  const btnAceptarHandler = () => {
    const selectedSector = sectores.find(
      (s) =>
        s.Id === +(document.querySelector('select[name="cbSector"]') as HTMLSelectElement).value
    )
    if (!selectedSector) {
      toastError('Error')
    } else {
      dispatch(auth.actions.setSector(selectedSector))
      window.location.reload()
      onClose()
    }
  }
  return (
    <Modal show onHide={onClose}>
      <Modal.Header>
        <Modal.Title>{intl.formatMessage({id: 'SETTINGS.SELECT_SECTOR'})}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {sectoresLoading ? (
          <>
            <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true' />{' '}
            {intl.formatMessage({id: 'SETTINGS.LOADING'})}
          </>
        ) : (
          <div className='mb-3'>
            <select name='cbSector' className='form-select'>
              {sectores.map((sector) => {
                return (
                  sector.Estado === EstadoType.Activo && (
                    <option
                      key={nanoid()}
                      value={sector.Id}
                      selected={sectorActual.Id === sector.Id}
                    >
                      {sector.Nombre}
                    </option>
                  )
                )
              })}
            </select>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='light-secondary' className='mx-2' onClick={onClose}>
          {intl.formatMessage({id: 'SETTINGS.CLOSE'})}
        </Button>
        <Button
          type='submit'
          variant='primary'
          className='mx-2'
          name='btnAceptar'
          onClick={btnAceptarHandler}
        >
          {intl.formatMessage({id: 'SETTINGS.ACCEPT'})}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalSectores
