import {FC, createContext, useContext} from 'react'
import {isDefined} from './i18nProvider'
import {IdiomaType} from '../../app/models/DTOs/common/types'

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY ?? 'i18nConfig'

type Props = {
  selectedLang: IdiomaType
}

// let navLang: any = navigator.language.split('-')[0]
// if (!isDefined(navLang)) navLang = undefined

const initialState: Props = {
  selectedLang: IdiomaType.Espaniol,
}

function getConfig(): Props {
  const ls = localStorage.getItem(I18N_CONFIG_KEY)

  if (ls) {
    try {
      return JSON.parse(ls) as Props
    } catch (er) {
      console.error(er)
    }
  }
  return initialState
}

// Side effect
export function setLanguage(lang: string) {
  const selectedLang = getConfig()
  if (lang === selectedLang.selectedLang) return
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: lang}))
  window.location.reload()
}

const I18nContext = createContext<Props>(initialState)

const useLang = () => {
  return useContext(I18nContext).selectedLang
}

const MetronicI18nProvider: FC = ({children}) => {
  const lang = getConfig()
  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>
}

export {MetronicI18nProvider, useLang}
