import React, {Suspense, lazy} from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {Redirect, Route, Switch} from 'react-router-dom'
import {RootState} from '../../setup'
import {FallbackView} from '../../_metronic/partials'
import {UserModel} from '../models/UserModel'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {RolUsuario} from '../models/DTOs/common/types'
import {Logout} from '../pages/auth'

export function PrivateRoutes() {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const AccountPage = lazy(() => import('../pages/account/AccountPage'))
  const UsersList = lazy(() => import('../pages/users-list/UsersList'))
  const Tickets = lazy(() => import('../pages/tickets/Tickets'))
  const Laboratorio = lazy(() => import('../pages/laboratorio/Laboratorio'))
  const Sectores = lazy(() => import('../pages/sectores/Sectores'))
  const ParametrizacionGeneral = lazy(
    () => import('../pages/parametrizacion-general/ParametrizacionGeneral')
  )
  const Lotes = lazy(() => import('../pages/lotes/Lotes'))
  const Equipos = lazy(() => import('../pages/equipos/Equipos'))
  const ProcedimientosMedida = lazy(
    () => import('../pages/procedimientos-de-medida/ProcedimientosMedida')
  )
  const EDA = lazy(() => import('../pages/eda/EDA'))
  const ReglasControl = lazy(() => import('../pages/reglas-de-control/ReglasControl'))
  const LimitesGraficosControl = lazy(
    () => import('../pages/limites-de-graficos-de-control/LimitesGraficosControl')
  )

  const isAdmin = user.Rol.Name === RolUsuario.AdministradorLaboratorio

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        {isAdmin && <Route path='/userslist' component={UsersList} />}
        {isAdmin && <Route path='/lab' component={Laboratorio} />}
        {isAdmin && <Route path='/sectors' component={Sectores} />}
        {/* {isAdmin && <Route path='/general-parameterization' component={ParametrizacionGeneral} />} */}
        {isAdmin && <Route path='/lots' component={Lotes} />}
        {isAdmin && <Route path='/equipment' component={Equipos} />}
        {isAdmin && <Route path='/measurement-procedures' component={ProcedimientosMedida} />}
        {isAdmin && <Route path='/edas' component={EDA} />}
        {isAdmin && <Route path='/control-rules' component={ReglasControl} />}
        {isAdmin && <Route path='/control-chart-limits' component={LimitesGraficosControl} />}
        <Route path='/account' component={AccountPage} />
        <Route path='/support/tickets' component={Tickets} />
        <Route path='/logout' component={Logout} />

        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
