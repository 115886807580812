import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'
import {useSelector, shallowEqual} from 'react-redux'
import {UserModel} from '../../../../app/models/UserModel'
import {RootState} from '../../../../setup'
import {RolUsuario} from '../../../../app/models/DTOs/common/types'

export function AsideMenuMain() {
  const intl = useIntl()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  return (
    <>
      <AsideMenuItem
        id='pagPrincipal'
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      {user.Rol.Name === RolUsuario.AdministradorLaboratorio && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                {intl.formatMessage({id: 'MENU.TITLE.INSTITUTION'})}
              </span>
            </div>
          </div>
          {/* <AsideMenuItem
            id='parametrizacionGeneral'
            to='/general-parameterization'
            icon='/media/icons/duotune/coding/cod001.svg'
            title={intl.formatMessage({id: 'MENU.GENERAL_PARAMETERIZATION'})}
            fontIcon='bi-app-indicator'
          /> */}
          <AsideMenuItem
            id='equipos'
            to='/equipment'
            icon='/media/icons/duotune/medicine/med008.svg'
            title={intl.formatMessage({id: 'MENU.EQUIPMENT'})}
            fontIcon='bi-app-indicator'
          />
          <AsideMenuItem
            id='lotesMaterialesControl'
            to='/lots'
            icon='/media/icons/duotune/medicine/med004.svg'
            title={intl.formatMessage({id: 'MENU.LOTS'})}
            fontIcon='bi-app-indicator'
          />
          <AsideMenuItem
            id='limitesGraficosControl'
            to='/control-chart-limits'
            icon='/media/icons/duotune/graphs/gra002.svg'
            title={intl.formatMessage({id: 'MENU.CONTROL_CHART_LIMITS'})}
            fontIcon='bi-app-indicator'
          />
          <AsideMenuItem
            id='reglasControl'
            to='/control-rules'
            icon='/media/icons/duotune/general/gen031.svg'
            title={intl.formatMessage({id: 'MENU.CONTROL_RULES'})}
            fontIcon='bi-app-indicator'
          />

          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                {intl.formatMessage({id: 'MENU.SECTORS'})}
              </span>
            </div>
          </div>
          <AsideMenuItem
            id='procedimientosMedida'
            to='/measurement-procedures'
            icon='/media/icons/duotune/medicine/med005.svg'
            title={intl.formatMessage({id: 'MENU.MEASUREMENT_PROCEDURES'})}
            fontIcon='bi-app-indicator'
          />
          <AsideMenuItem
            id='eda'
            to='/edas'
            icon='/media/icons/duotune/general/gen032.svg'
            title={intl.formatMessage({id: 'MENU.EDA'})}
            fontIcon='bi-app-indicator'
          />

          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                {intl.formatMessage({id: 'MENU.TITLE.ADMIN'})}
              </span>
            </div>
          </div>
          <AsideMenuItem
            id='laboratorio'
            to='/lab'
            icon='/media/icons/duotune/finance/fin001.svg'
            title={intl.formatMessage({id: 'MENU.INSTITUTION'})}
            fontIcon='bi-app-indicator'
          />
          <AsideMenuItem
            id='sectores'
            to='/sectors'
            icon='/media/icons/duotune/abstract/abs026.svg'
            title={intl.formatMessage({id: 'MENU.SECTORS'})}
            fontIcon='bi-app-indicator'
          />
          <AsideMenuItem
            id='listaUsuarios'
            to='/userslist'
            icon='/media/icons/duotune/communication/com005.svg'
            title={intl.formatMessage({id: 'MENU.USERS'})}
            fontIcon='bi-app-indicator'
          />
        </>
      )}
    </>
  )
}
