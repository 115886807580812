export enum RolUsuario {
  Administrador = 'administrador',
  ResponsableLaboratorio = 'responsable-laboratorio',
  AdministradorLaboratorio = 'administrador-laboratorio',
}

export enum EstadoTicket {
  Abierto = 'open',
  Cerrado = 'closed',
}

export enum TipoTicket {
  Administrativo = 'administrativo',
  SoporteBioquimico = 'soporte-bioquimico',
  SoporteTecnico = 'soporte-tecnico',
}

export enum EstadoType {
  Activo = 'enabled',
  Inactivo = 'disabled',
}

export enum VisibilidadType {
  Visible = 'visible',
  Oculto = 'hidden',
}

export enum IdiomaType {
  Espaniol = 'es',
  Ingles = 'en',
}

export enum EsquemaType {
  Tradicional = 1,
  C24,
  RangoFijo,
}

export enum CalculoValorObjetivoType {
  MediaEspecifica,
  MediaPeriodoTiempo,
  RangoFijo,
}

export enum CalculoStdDevType {
  StdDevEspecifica,
  StdDevPeriodoTiempo,
  CVEspecifico,
  CVPeriodoTiempo,
  StdDevPooled,
  CVPromedioPonderado,
}
