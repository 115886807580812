import {API_OLD_URL, API_URL} from './API_URLHelper'

const ROUTES = {
  USUARIOS: `${API_URL}/usuarios`,
  ACCOUNT: `${API_URL}/account`,
  PAISES: `${API_URL.replace('/admin', '')}/paises`,
  TICKETS: `${API_URL}/support/tickets`,
  INSTITUCIONES: `${API_URL}/laboratorios`,
  LABORATORIO: `${API_URL}/laboratorio`,
  AUTH: `${API_URL}/auth`,
  AUTH_PASSWORD: `${API_URL}/auth/password`,
  APLICACIONES_OLD: `${API_OLD_URL}/aplicaciones`,
  BATCH_USUARIOS: `${API_URL}/batch/usuarios`,
  SECTORES: `${API_URL}/sectores`,
  USUARIOS_SECTORES: `${API_URL}/usuarios/sectores`,
  REACTIVOS: `${API_URL}/reactivos`,
  PRINCIPIOS_MEDIDA: `${API_URL}/principios-medida`,
  PLATAFORMAS: `${API_URL}/plataformas`,
  ANALITOS: `${API_URL}/analitos`,
  UNIDADES: `${API_URL}/unidades`,
  MATRICES: `${API_URL}/matrices`,
  MATERIALES_CONTROL: `${API_URL}/materiales-control`,
  MATERIALES_CONTROL_LOTES: `${API_URL}/materiales-control/:id/lotes-control`,
  MATERIALES_CONTROL_LOTES_OLD: `${API_OLD_URL}/materialesControl/:id/lotes`,
  EQUIPOS: `${API_URL}/equipos`,
  SECTORES_PROCEDIMIENTOS_MEDIDA: `${API_URL}/sectores/:id/procedimientosMedida`,
  PROCEDIMIENTOS_MEDIDA: `${API_URL}/procedimientos-medida`,
  EDAS: `${API_URL}/procedimientosMedida/edas`,
  EDAS_FUENTES: `${API_URL}/edas/fuentes`,
  REGLAS_CONTROL_OLD: `${API_OLD_URL}/cc/reglasControl`,
  CC: `${API_URL}/cc`,
  CC_OLD: `${API_OLD_URL}/cc`,
  GRUPOS_LABORATORIOS: `${API_URL}/grupos-laboratorios`,
}

export default ROUTES
